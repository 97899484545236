<template>
    <div id="anchor-list">

        <!-- <el-button type="primary" @click="batchMarkLive()">批量纪录接档</el-button> -->

        <el-popconfirm cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="确认批量纪录接档？"
            @confirm="batchMarkLive();">
            <el-button type="primary" slot="reference">批量纪录接档</el-button>
        </el-popconfirm>

        <el-divider></el-divider>

        <el-form :model="form" ref="form" inline>
            <el-form-item label="ID">
                <el-input v-model="form.id"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="微信号">
                <el-input v-model="form.wechat"></el-input>
            </el-form-item>
            <el-form-item label="抖音号">
                <el-input v-model="form.dy"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>



        <el-table :data="page.list" border @selection-change="select">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column label="ID" width="60" prop="id">

            </el-table-column>
            <el-table-column label="基本信息" width="200">
                <template v-slot="row">
                    {{ row.row.name }} / {{ row.row.age }} / {{ page.sexDesc[row.row.sex] }} <br>
                </template>
            </el-table-column>

            <el-table-column label="创建时间" prop="createTime" width="150">

            </el-table-column>
            <el-table-column label="经纪人" width="150">
                <template v-slot="row">
                    <template v-if="row.row.agentId">
                        [{{ row.row.agentId }}] {{ row.row.agentNickName }} </template>
                    <template v-else> 无 </template>

                </template>
            </el-table-column>

            <el-table-column label="目前直播间" width="180">
                <template v-slot="row">
                    <template v-if="row.row.studioId">
                        [{{ row.row.studioId }}] {{ row.row.studioName }}</template>
                    <template v-else>
                        未分配
                    </template>
                </template>
            </el-table-column>


            <el-table-column label="直播天数" prop="liveNums" width="100">
                <template v-slot="row">
                    <el-tag :type="getMatchTag(row.row.liveNums)" effect="dark">
                        <span style="font-size: large;"> {{ row.row.liveNums }}</span>
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作">
                <template v-slot="row">
                    <el-popover placement="left" trigger="click">
                        <el-descriptions class="margin-top" :column="2" border style="width: fit-content;">
                            <template slot="extra">
                                <el-button v-if="!row.row.edit" type="primary" size="small"
                                    @click="row.row.edit = true">修改</el-button>
                                <template v-else>
                                    <el-button type="primary" size="small" @click="edit(row.row);">保存</el-button>
                                    <el-button type="info" size="small" @click="row.row.edit = false;">取消</el-button>
                                </template>

                            </template>
                            <el-descriptions-item label="姓名">
                                <el-input v-if="row.row.edit" v-model="row.row.name"></el-input>
                                <span v-else>{{ row.row.name }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="年龄">
                                <el-input v-if="row.row.edit" v-model="row.row.age" type="number"></el-input>
                                <span v-else>{{ row.row.age }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="微信号">
                                <el-input v-if="row.row.edit" v-model="row.row.wechat"></el-input>
                                <span v-else>{{ row.row.wechat }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="抖音号">
                                <el-input v-if="row.row.edit" v-model="row.row.dy"></el-input>
                                <span v-else>{{ row.row.dy }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="性别">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.sex">
                                    <el-radio label="MALE">男</el-radio>
                                    <el-radio label="FEMALE">女</el-radio>
                                </el-radio-group>
                                <span v-else>{{ page.sexDesc[row.row.sex] }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="备注">
                                <el-input v-if="row.row.edit" v-model="row.row.remark"></el-input>
                                <span v-else>{{ row.row.remark }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="有无才艺">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.talent">
                                    <el-radio :label="true">有</el-radio>
                                    <el-radio :label="false">无</el-radio>
                                </el-radio-group>
                                <template v-else>
                                    <span v-if="row.row.talent">有</span>
                                    <span v-else>无</span>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label="才艺类型">

                                <template v-if="row.row.edit">
                                    <el-select v-if="row.row.talent" v-model="row.row.talentType" placeholder="请选择">
                                        <el-option v-for="talentType in editInfo.talentTypes" :key="talentType"
                                            :value="talentType" :label="page.talentTypeDesc[talentType]">
                                        </el-option>
                                    </el-select>
                                </template>
                                <span v-else>{{ page.talentTypeDesc[row.row.talentType] }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="入职类型">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.jobType">
                                    <el-radio label="FULL_TIME">全职</el-radio>
                                    <el-radio label="PART_TIME">兼职</el-radio>
                                </el-radio-group>
                                <span v-else> {{ page.jobTypeDesc[row.row.jobType] }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="资薪类型">
                                <el-select v-if="row.row.edit" v-model="row.row.salaryType" placeholder="请选择">
                                    <el-option v-for="salaryType in editInfo.salaryTypes" :key="salaryType"
                                        :value="salaryType" :label="page.salaryTypeDesc[salaryType]">
                                    </el-option>
                                </el-select>
                                <span v-else> {{ page.salaryTypeDesc[row.row.salaryType] }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="每天可接时间">
                                <el-checkbox-group v-if="row.row.edit" v-model="row.row.workTimeArr">
                                    <el-checkbox v-for="item in workTimeList" :label="item" :key="item"></el-checkbox>
                                </el-checkbox-group>
                                <span v-else>{{ row.row.workTime }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="意愿就职日期">
                                <el-date-picker v-if="row.row.edit" v-model="row.row.expectStartDate" type="date"
                                    placeholder="选择日期" value-format="yyyy-MM-dd">
                                </el-date-picker>
                                <span v-else>{{ row.row.expectStartDate }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="是否可以接超过2个月">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.takeLongTime">
                                    <el-radio :label="true">可以</el-radio>
                                    <el-radio :label="false">不可以</el-radio>
                                </el-radio-group>
                                <template v-else>
                                    <span v-if="row.row.takeLongTime">可以</span>
                                    <span v-else>不可以</span>
                                </template>
                            </el-descriptions-item>

                            <el-descriptions-item label="意愿直播间类型">
                                <el-select v-if="row.row.edit" v-model="row.row.expectStudioType" placeholder="请选择">
                                    <el-option v-for="studioType in editInfo.studioTypes" :key="studioType"
                                        :value="studioType" :label="page.studioTypeDesc[studioType]">
                                    </el-option>
                                </el-select>

                                <span v-else>{{ page.studioTypeDesc[row.row.expectStudioType] }}</span>
                            </el-descriptions-item>

                            <el-descriptions-item label="声卡类型">
                                <el-select v-if="row.row.edit" v-model="row.row.scType" placeholder="无" clearable>
                                    <el-option v-for="scType in editInfo.scTypes" :key="scType" :value="scType"
                                        :label="page.scTypeDesc[scType]">
                                    </el-option>
                                </el-select>
                                <template v-else>
                                    <span v-if="row.row.scType">
                                        {{ page.scTypeDesc[row.row.scType] }}
                                    </span>
                                    <span v-else>无</span>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label="是否可以进工会">

                                <el-radio-group v-if="row.row.edit" v-model="row.row.canJoinGuild">
                                    <el-radio :label="true">可以</el-radio>
                                    <el-radio :label="false">不可以</el-radio>
                                </el-radio-group>
                                <template v-else>
                                    <span v-if="row.row.canJoinGuild">可以</span>
                                    <span v-else>不可以</span>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label="是否有经验">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.haveExp">
                                    <el-radio :label="true">有</el-radio>
                                    <el-radio :label="false">无</el-radio>
                                </el-radio-group>
                                <template v-else>
                                    <span v-if="row.row.haveExp">有</span>
                                    <span v-else>无</span>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label="是否学生">
                                <el-radio-group v-if="row.row.edit" v-model="row.row.student">
                                    <el-radio :label="true">是</el-radio>
                                    <el-radio :label="false">否</el-radio>
                                </el-radio-group>
                                <template v-else>
                                    <span v-if="row.row.student">是</span>
                                    <span v-else>否</span>
                                </template>
                            </el-descriptions-item>

                        </el-descriptions>

                        <el-button type="info" slot="reference">详细信息</el-button>
                    </el-popover>

                    <template v-if="authInfo.auType != 'AGENT'">
                        <el-divider direction="vertical"></el-divider>

                        <el-popover placement="left" trigger="click" v-if="row.row.studioId == null">
                            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                <el-form-item>
                                    <el-select v-model="oprStudioId" placeholder="请选择">
                                        <el-option v-for="studio in editInfo.canOprStudios" :key="studio.id"
                                            :value="studio.id" :label="'[' + studio.id + ']' + studio.name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary"
                                        @click="editStudio(row.row.id, oprStudioId)">提交</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary" slot="reference">分配直播间</el-button>
                        </el-popover>

                        <el-popconfirm v-else confirm-button-text='确认' cancel-button-text='取消' icon="el-icon-info"
                            icon-color="red" title="确认移除？" @confirm="editStudio(row.row.id, null);">
                            <el-button type="danger" slot="reference">移出直播间</el-button>
                        </el-popconfirm>

                    </template>

                    <template v-if="authInfo.auType == 'ROOT'">
                        <el-divider direction="vertical"></el-divider>
                        <el-popover placement="left" trigger="click">
                            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                <el-form-item>
                                    <el-select v-model="oprAgentId" placeholder="请选择">
                                        <el-option v-for="agent in editInfo.canOprAgents" :key="agent.id"
                                            :value="agent.id" :label="'[' + agent.id + ']' + agent.nickName">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="editAgent(row.row.id, oprAgentId)">提交</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary" slot="reference">修改经纪人</el-button>
                        </el-popover>
                    </template>


                    <template v-if="authInfo.auType != 'AGENT'">
                        <el-divider direction="vertical"></el-divider>
                        <el-popover placement="left" trigger="click" v-if="row.row.studioId">
                            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                <el-form-item>
                                    <el-date-picker v-model="liveDate" type="date" placeholder="选择日期"
                                        value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="markLive(row.row.id)">提交</el-button>
                                </el-form-item>
                            </el-form>
                            <el-button type="primary" slot="reference">纪录接档</el-button>
                        </el-popover>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: "AnchorList",
    data() {
        return {
            workTimeList: ['6-8', '8-10', '10-12', '12-14', '14-16', '16-18', '18-20', '20-22', '22-24', '0-2', '2-4', '4-6'],

            form: {
                pageNo: 1,
                pageSize: 15,
                name:null,
                id:null,
                wechat:null,
                dy:null,
            },

            oprStudioId: null,

            oprAgentId: null,

            liveDate: null,

            editInfo: {
                canOprStudios: null,
                canOprAgents: null,
                talentTypes: null,
                jobTypes: null,
                salaryTypes: null,
                studioTypes: null,
                scTypes: null,
            },

            page: {
                list: null,
                totalPage: 1,
                sexDesc: null,
                jobTypeDesc: null,
                talentTypeDesc: null,
                studioTypeDesc: null,
                scTypeDesc: null,
                salaryTypeDesc: null,
            },

            selectedIds: [],
        };
    },

    methods: {

        select(ids) {
            this.selectedIds = [];
            for (var i = 0; i < ids.length; i++) {
                var item = ids[i];
                this.selectedIds.push(item.id);
            }
            console.log(this.selectedIds)
        },

        loadList() {
            this.axios.get("/anchor/list", { params: this.form }).then((resp) => {
                if (resp.data.success) {
                    resp.data.data.list.forEach((element) => {
                        element.canEditStudio = (element.studioId == null && resp.data.data.canOprStudios != null);
                        element.edit = false;
                    });
                    this.page = resp.data.data;
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        changePage(val) {
            this.form.pageNo = val;
            this.loadList();
        },

        loadEditInfo() {
            this.axios.get("/anchor/editInfo", {}).then((resp) => {
                if (resp.data.success) {
                    this.editInfo = resp.data.data;
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        editStudio(anchorId, studioId) {
            var data = {
                anchorId: anchorId,
                studioId: studioId,
            }
            this.axios.post("/anchor/editStudio", data).then((resp) => {
                this.oprStudioId = null;
                if (resp.data.success) {
                    this.$message.success('操作成功');

                } else {
                    this.$message.error(resp.data.msg);
                }

            });
        },

        editAgent(anchorId, agentId) {
            var data = {
                anchorId: anchorId,
                agentId: agentId,
            }
            this.axios.post("/anchor/editAgent", data).then((resp) => {
                this.oprAgentId = null;
                if (resp.data.success) {
                    this.$message.success('操作成功');
                    location.reload();
                } else {
                    this.$message.error(resp.data.msg);
                }

            });
        },

        edit(anchor) {
            if (anchor.scType == '') {
                anchor.scType = null;
            }
            this.axios.post("/anchor/edit", anchor).then((resp) => {
                this.oprStudioId = null;
                if (resp.data.success) {
                    this.$message.success('操作成功');

                } else {
                    this.$message.error(resp.data.msg);
                }

            });
        },

        markLive(anchorId) {
            var data = {
                anchorId: anchorId,
                liveDate: this.liveDate,
            }
            this.axios.post("/lr/mark", data).then((resp) => {
                this.liveDate = null;
                if (resp.data.success) {
                    this.$message.success('操作成功');
                    location.reload();
                } else {
                    this.$message.error(resp.data.msg);
                }


            });
        },

        batchMarkLive() {

            var data = {
                ids: this.selectedIds,
            }


            this.axios.post("/lr/batchMark", data).then((resp) => {
                this.liveDate = null;
                if (resp.data.success) {

                    var msg = "";
                    if (resp.data.data.successNums > 0) {
                        msg += '成功纪录：<i>' + resp.data.data.successNums + "</i><br>";
                        // this.$message({
                        //     showClose: true,
                        //     message: '成功纪录：' + resp.data.data.successNums,
                        //     type: 'success',
                        // });
                    }
                    if (resp.data.data.failNums > 0) {
                        // this.$message({
                        //     showClose: true,
                        //     message: resp.data.data.failDetail,
                        //     type: 'error',
                        // });
                        msg += '<hr />';
                        msg += '纪录失败：<i>' + resp.data.data.failNums + "</i><br>";
                        msg += resp.data.data.failDetail;
                    }

                    this.$message({
                        dangerouslyUseHTMLString: true,
                        showClose: true,
                        message: msg,

                    });
                    // location.reload();
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        getMatchTag(liveNums) {
            if (liveNums >= 7) {
                return 'success'
            } else if (liveNums > 0) {
                return 'info'
            } else {
                return 'danger'
            }
        },

    },

    created() {
        this.loadList();
        this.loadEditInfo();
    },

    computed: {
        authInfo: function () {
            return this.$store.state.auth.authInfo;
        },
    },
};
</script>

<style scoped></style>